<template>
  <div class="about p-5 overflow-hidden container-fluid" id="dementie-information">
    <div v-if="Object.keys(this.userInfo).length==0" class="alert alert-danger" role="alert">
      U hebt geen toegang tot deze pagina. 
    </div>


    <div v-if="Object.keys(this.userInfo).length!=0">
    <!-- Only display the data if the errorMessage property is not set -->
    <h1 class="intro-section-title">Netwerkanalyse</h1>

    <div class="form" ref="formElement">
      <div v-if="index===1">
        <div class="row">
          <div class="col-sm-12 down">
            <div class="intro-section-colour-block">
              <div class="row">
                <p class="intro-section-text">Beste deelnemer,</p>
                <p class="intro-section-text">Allereerst hartelijk dank voor je medewerking aan deze netwerkanalyse van de <a tabindex="0" class="link" href="https://www.zorgstandaarddementie.nl/zorgstandaard/" target="_blank" autofocus>Zorgstandaard Dementie (ZSD)</a>. De netwerkcoördinator dementie heeft jou gevraagd om deel te nemen vanwege jouw betrokkenheid of ervaring met de zorg en ondersteuning van mensen met dementie. De vragen die je gaat beantwoorden zijn bedoeld om inzicht te geven in de mate waarin de zorg en ondersteuning in jouw regio al is georganiseerd volgens de Zorgstandaard Dementie.</p>
                
                <p class="intro-section-text">We stellen je eerst enkele vragen over jezelf en het netwerk waar je in werkt. Daarna volgen vragen over de mate waarin de aanbevelingen uit de Zorgstandaard zijn gerealiseerd. Door bij iedere pagina op ‘Volgende’ te klikken worden de antwoorden tussentijds opgeslagen. Mogelijk kun jij niet bij alle aanbevelingen aangeven in hoeverre deze zijn gerealiseerd. Kies dan de antwoordoptie ‘Hier heb ik geen zicht op’. Uitsluitend de netwerkcoördinator die jou heeft uitgenodigd heeft zicht op jouw individuele resultaten. Wel kunnen deze samengevat en geanonimiseerd worden verwerkt voor onderzoeksdoeleinden. Door de vragenlijst te starten en te klikken op ‘Volgende’ onderaan deze pagina ga je akkoord met dit gebruik van de gegevens.</p>
                
                <p class="intro-section-text">Je kunt de vragenlijst alleen invullen via deze website. Het is ook mogelijk hem te <a tabindex="0" class="link" href="https://www.zorgstandaarddementie.nl/wp-content/uploads/2023/06/Netwerkanalyse-ZSD.pdf" target="_blank"> downloaden </a> als PDF-bestand ter informatie.</p>
                
                <p class="intro-section-text">Als je een vraag hebt, kun je hiermee terecht bij het secretariaat van DNN: (<a class="link" tabindex="0" href="mailto:secretariaat@dementienetwerknederland.nl">secretariaat@dementienetwerknederland.nl</a>).</p>
              </div>
            </div>
            <div class="intro-section-cc"></div>
          </div>
    </div>





        <div class="mb-3 row"></div>
        <div class="select-klant d-flex justify-content-between align-items-center">
          <label 
          class='col-sm-6 label-text' 
          for="SelectRole">Ben je een cliënt, een naaste van een cliënt, een vrijwilliger of een professional?</label>
          <b-form-select 
          aria-label="Selecteer uw rol (klant, geliefde, vrijwilliger, professional)" 
          id="SelectRole" autocomplete="on"
          v-model="respondent.klant" 
          :options="klant" 
          size="lg" 
          class="col-sm-6 custom-select-form" 
          required></b-form-select>
      </div>

      <div class="mb-3 row"></div>
        <div v-if='respondent.klant === "Professional" || respondent.klant === "Overig"' class="select-sector d-flex justify-content-between align-items-center">
          <label class='col-sm-6 label-text' for="Sector">In welke sector werkt je voornamelijk als professional?</label>
          <b-form-select v-model="respondent.sector" :options="sector" size="lg" class="col-sm-6 custom-select-form" required></b-form-select>
      </div>

      <div class="mb-3 row"></div>
        <div v-if='respondent.klant === "Professional" || respondent.klant === "Overig"' class="select-functie d-flex justify-content-between align-items-center">
          <label class='col-sm-6 label-text' for="Functie">Wat is je functie? </label>
          <b-form-select v-model="respondent.functie" :options="functie" size="lg" class="col-sm-6 custom-select-form" required></b-form-select>
      </div>

      <div class="mb-3 row"></div>
        <div v-if='respondent.klant === "Professional" || respondent.klant === "Overig"' class="select-werkt d-flex justify-content-between align-items-center">
          <label class='col-sm-6 label-text' for="Werkt">Werk je voornamelijk intramuraal of voornamelijk extramuraal?</label>
          <b-form-select v-model="respondent.werkt" :options="werkt" size="lg" class="col-sm-6 custom-select-form" required></b-form-select>
      </div>

      <button @click="nextQuestionGroup" class="btn btn-primary lager" :disabled="isDisabled1">Volgende</button>
      </div>



       <div v-if="index===2 && questions">
        <div class="mb-3 row"></div>

          <div class="information-text" autofocus tabindex="0">
            <p class="p-text">Realisatie aanbevelingen Zorgstandaard Dementie <br> <br>
              Hieronder leggen we u een aantal stellingen voor. Deze stellingen zijn gebaseerd op de aanbevelingen uit de Zorgstandaard Dementie. Geef per stelling aan in hoeverre dit binnen uw netwerk is gerealiseerd.
            <br><span class="p-italic">(1 = helemaal niet gerealiseerd, 2 = grotendeels niet gerealiseerd, 3 = ongeveer de helft gerealiseerd, 4 = grotendeels gerealiseerd, 5 = volledig gerealiseerd).</span>
            </p>
          </div>

        <div>
          <div class="mb-3 row">
            <div v-for="(question, index) in filteredQuestions" :key="question.id">
              <h2 v-if="shouldDisplayHeader(index)" class="question-group">{{ question.vragengroep }}</h2>
              <fieldset>
                <legend class="sr-only">{{ question.vraag }}</legend>
                <div class="mb-3 row">
                  <p class="question">{{ question.vraag }}</p>
                    <div class="rb">
                      <label 
                      
                      class="rb-tab" 
                      :class="{ 'rb-tab-active': question.antwoord == item.value }" 
                      v-for="(item, idx) in options" 
                      :key="`1a-inner-loop-tab-${idx}`">
                        <input 
                          class="rb-input"
                          v-model="question.antwoord" 
                          :value="item.value" 
                          type="radio" 
                          :name="`question-${index}`" 
                          :id="`radioButton-${idx}`" 
                          :checked="question.antwoord == item.value" 
                          required 
                         >
                          <div 
                          class="rb-spot" 
                          tabindex="0" 
                          @keyup.enter="selectRadioButton(question, item.value)"
                          :class="{ 'rb-spot-active': question.antwoord == item.value }"
                          >
                          <span v-if="item.value!=6" class="rb-txt">{{ item.text }}</span>
                          <span class="rb-txt-nvt">{{ item.text2 }}</span>
                        </div>
                      </label>
                      <p></p>
                    </div>
                  </div>
                </fieldset>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <div class="d-flex justify-content-start flex-grow-1 bd-highlight">
                <button v-if="this.vragengroep_id == 1" type="button" @click="previousPage" class="btn btn-primary lager">Vorige pagina</button>
                <button v-if="this.vragengroep_id > 1" type="button" @click="PrevQuestionGroup" class="btn btn-primary lager">Vorige pagina</button>
              </div>
              <div class="d-flex justify-content-end">
                <button id="nextPageButton" v-if="this.vragengroep_id < 10 && this.respondent.klant != 'Cliënt' && this.respondent.klant != 'Naaste van een cliënt'" type="button" @click="nextQuestionGroup" class="btn btn-primary lager" :disabled="isDisabledNext">Volgende pagina</button>
                <button id="nextPageButton" v-if="this.vragengroep_id < 7 && this.respondent.klant == 'Cliënt'" type="button" @click="nextQuestionGroup" class="btn btn-primary lager" :disabled="isDisabledNext">Volgende pagina</button>
                <button id="nextPageButton" v-if="this.vragengroep_id < 8 && this.respondent.klant == 'Naaste van een cliënt'" type="button" @click="nextQuestionGroup" class="btn btn-primary lager" :disabled="isDisabledNext">Volgende pagina</button>
                <button v-if="this.vragengroep_id == 10 && this.respondent.klant != 'Cliënt' && this.respondent.klant != 'Naaste van een cliënt'" type="submit" @click="nextQuestionGroup" class="btn btn-primary lager" :disabled="isDisabled2">Verstuur</button>
                <button v-if="this.vragengroep_id == 7 && this.respondent.klant == 'Cliënt'" type="submit" @click="nextQuestionGroup" class="btn btn-primary lager" :disabled="isDisabled2">Verstuur</button>
                <button v-if="this.vragengroep_id == 8 && this.respondent.klant == 'Naaste van een cliënt'" type="submit" @click="nextQuestionGroup" class="btn btn-primary lager" :disabled="isDisabled2">Verstuur</button>
              </div>
            </div>
        </div>
  </div>
      <div v-if="index===3">
          <div v-if="saved" class="alert alert-success custom-alert" role="alert">
            Antwoorden opgeslagen, bedankt!
          </div>
          <button class="btn btn-secondary" @click="backToMenu" >Terug naar menu</button>
        </div>
      </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueMeta from 'vue-meta'
  export default {
    mixins: [VueMeta],
    metaInfo: {
      title: 'ZDS-Respondent Netwerkanalyse',
    },
    data() {
      return {
         respondent: {
          dementienetwerk: '',
          klant: '',
          functie: '',
          sector: '',
          werkt: '',
          antwoorden: [],
        },
        options: [
          { text: '1', text2: 'Helemaal niet gerealiseerd', value: 1 },
          { text: '2', text2: 'Grotendeels niet gerealiseerd', value: 2 },
          { text: '3', text2: 'Ongeveer de helft gerealiseerd', value: 3 },
          { text: '4', text2: 'Grotendeels gerealiseerd', value: 4 },
          { text: '5', text2: 'Volledig gerealiseerd', value: 5 },
          { text2: 'Hier heb ik geen zicht op', value: 6 },
        ],
        klant: ['Cliënt', 'Naaste van een cliënt', 'Vrijwilliger', 'Professional','Overig'],
        sector: ['In het ziekenhuis', 'In de huisartsenzorg','In de geestelijke gezondheidszorg', 'In een verpleeghuis en/of verzorgingshuis', 'In de thuiszorg','Bij een welzijnsorganisatie', 'Bij een gemeente','Overig'],
        functie: ['Netwerkcoördinator, ketenregisseur', 'Casemanager dementie/Trajectbegeleider/Dementieverpleegkundige', 'Verzorgende of helpende','Verpleegkundig specialist', '(Wijk)verpleegkundige', 'Huishoudelijke hulp bij mensen met dementie','Manager, bestuurder, beleidsmedewerker','Teamleider', 'Huisarts', ' Paramedicus (zoals ergotherapeut, POH\'er of psycholoog)', 'Sociaal werker','Vrijwilligerscoördinator','Mantelzorgondersteuner- of consulent','Geriater, specialist ouderengeneeskunde of neuroloog','Woonconsulent','Medewerker bij dagactiviteiten voor mensen met dementie','WMO-consulent, WMO-coach, WMO-vakspecialist of beleidsadviseur WMO','Overig'],
        werkt: ['Ik werk volledig intramuraal','Ik werk meer intramuraal dan extramuraal', 'Ik werk ongeveer evenveel intramuraal als extramuraal','Ik werk meer extramuraal dan intramuraal', 'Ik werk volledig extramuraal', 'Niet van toepassing voor mijn functie'],
        koppeltabel: [],
        koppeltabelFunctieomschrijving: [],
        //filteredQuestions: [],
        vragengroep:'',
        vraag:'',
        vraag_id : 1,
        vragengroep_id:1,
        id: '',
        index: 1,
        user_antwoord_id:0,
        questions: null,
        saved: false,
        error: false,
      }
    },
    computed: {
      ...mapGetters({userInfo: 'userInfo' }),

    filteredQuestions() {
      if (this.questions) {
        // a list of unique vragengroep values
        const uniqueGroups = [...new Set(this.questions.map(question => question.vragengroep))];
        // filtering the questions based on the current vragengroup id
        const filteredQuestions = this.questions.filter((question) => {
          this.vragengroep =  question.vragengroep;
          return question.vragengroep === uniqueGroups[this.vragengroep_id - 1];
          })
        return filteredQuestions
      } else {
         const filteredQuestions = []
         return filteredQuestions
      }
    },

      isDisabled1() {
        return (this.respondent.klant == '' || (this.respondent.klant == 'Professional' && (this.respondent.sector == '' || this.respondent.werkt == '' || this.respondent.functie == ''))) == true
      },
      isDisabled2() {
        return (this.filteredQuestions.some(obj => obj.antwoord == '' || typeof obj.antwoord === 'undefined') || this.respondent.klant == '') == true
      },
      isDisabledNext(){
        this.$refs.formElement.focus();

        if (this.filteredQuestions.some(obj => obj.antwoord == '' || typeof obj.antwoord === 'undefined')) {
          return true;
        }
        else{
          return false;
         }
      },
    },
    methods: {
      selectRadioButton(question, selectedValue) {
        question.antwoord = selectedValue;
      },
      initialQuestions(){
        this.$http.get(`/api/vragen_netwerkanalyse/?klant=${this.respondent.klant}`).then((response) => {
            response.data.sort((a,b) => a.id-b.id)
            this.questions = response.data.map((question, index) => {
              const koppeltabelnetwerkanalyse = question.koppeltabelnetwerkanalyse[0]
              return {
                id: question.id,
                vraag: question.vraag,
                vraag_id : koppeltabelnetwerkanalyse.vraag_id,
                vragengroep_id: koppeltabelnetwerkanalyse.vragengroep_id,
                vragengroep: koppeltabelnetwerkanalyse.vragengroep,
                antwoord : this.user_antwoord_id[index] || ''
              }
            })
          })
      },
      shouldDisplayHeader(index) {
      // checking if the current question has the same vragengroep value as the previous question
      if (index === 0 || this.filteredQuestions[index].vragengroep !== this.filteredQuestions[index - 1].vragengroep) {
        return true
      } else {
        return false
      }
    },
    nextQuestionGroup(){
        // assigning dementienetwerk and user id to the data
        //this.netwerkcoordinator.dementienetwerk=localStorage.getItem('Dementienetwerk')
        this.respondent.dementienetwerk = this.userInfo.dementienetwerk
        this.respondent.user_id = this.userInfo.id
        this.respondent.antwoorden=this.filteredQuestions
        if (this.respondent.klant === 'Cliënt'||this.respondent.klant === 'Naaste van een cliënt'||this.respondent.klant === 'Vrijwilliger') {
          this.respondent.sector = ''
          this.respondent.functie = ''
          this.respondent.werkt = ''
        }

        // sending the API to save answers when clicked on button Next in each page
        if (this.index === 2) {
          const requestBody = {
          respondent : this.respondent,
          submit_at_next : true // parameter to send backend for submitting on the next button
        }
          this.$http.post('/api/respondenten_netwerkanalyse/', requestBody)

          if ((this.vragengroep_id == 10 && this.respondent.klant != 'Cliënt' && this.respondent.klant != 'Naaste van een cliënt')||(this.vragengroep_id == 7 && this.respondent.klant == 'Cliënt')||(this.vragengroep_id == 8 && this.respondent.klant == 'Naaste van een cliënt')) {
            this.questions = null
            this.index = this.index + 1
          }
          else{
            this.vragengroep_id = this.vragengroep_id + 1
          }
        }

        if (this.index === 1) {
          const requestBody = {
          respondent : this.respondent,
          submit_at_next : false
        }
          this.$http.post('/api/respondenten_netwerkanalyse/', requestBody)
          this.index = this.index + 1
          this.initialQuestions()
          // this.loadData(2)
        }

        if (this.index == 3) {
          this.saved = true
        }

        const nextPageButton = document.getElementById('nextPageButton');
        if (nextPageButton) {
          nextPageButton.blur();
        }

        this.$nextTick(() => {
          const formElement = document.querySelector('.form');
          if (formElement) {
            formElement.focus();
          }
        });
        this.$refs.formElement.focus();

      },
      onSubmit() {
        this.respondent.dementienetwerk=localStorage.getItem('Dementienetwerk')
        this.respondent.antwoorden=this.filteredQuestions
        this.$http.post('/api/respondenten_netwerkanalyse/', this.respondent)
        this.saved = true
        this.questions = null
      },
      previousPage() {
        if (this.index == 2) {
          this.vragengroep_id = 1;
        }
        this.index = this.index - 1
      },
      volgende() {
          this.index = this.index + 1
          this.filteredQuestions()
          this.filteredQuestions = this.questions.filter(question => this.questionFilter().includes(question.id))
      },
      vorige() {
        if (this.index>1) {
          this.index = this.index - 1
        }
      },
      PrevQuestionGroup(){
        if (this.vragengroep_id === 0) {
          this.vragengroep_id = 0
        }
        else{
          this.vragengroep_id = this.vragengroep_id - 1
        }
      },
      isDisabledPrev(){
        return (this.vragengroep_id == 1) == true
      },

      loadData(number_of_times){
        this.$http.get(`api/respondenten_netwerkanalyse/get_antwoord_based_on_user_netwerkanalyse/?number_of_times=${number_of_times}`).then((response)=>{
          
          // check if the response is array or not
          if (Array.isArray(response.data)) {
            response.data.sort((a,b) => a.id - b.id)
            this.user_antwoord_id = response.data.map(d => d.antwoord_id) // answer id
            this.user_vraag_id = response.data.map(d => d.vraag_id) // question id
            this.user_klant = response.data.map(d => d.klant) // first page questions
            this.user_sector= response.data.map(d => d.uren)
            this.user_werkt= response.data.map(d => d.werkt)
            this.user_functie = response.data.map(d => d.functie)

            if (response.data[0]) {
              this.respondent.functie = response.data[0].functie;
              this.respondent.klant = response.data[0].klant;
              this.respondent.werkt = response.data[0].werkt;
              this.respondent.sector = response.data[0].sector;
            } else {
              this.respondent.functie = '';
              this.respondent.uren = '';
              this.respondent.besproken = '';
            }
          }
        })
      },
      backToMenu() {
        this.$router.push('/zorgstandaard/Netwerkcoordinator/')
      },
    },
    mounted () {
      // load previous data filled by user if any
      this.initialQuestions();
      this.loadData(1);
    },
  }
</script>

<style scoped>

.title{
  margin-bottom: 2rem;
}

.col-sm-1 {
text-align: center;
}
.col-sm-6 {
text-align: left;
}
.mb-3{
text-align: left;
}
.col-sm-3 {
  text-align: center;
}
.col-md-3 {
  margin-left: 5px;
}
.col-sm-2 {
  text-align: center;
}

.lager {
  outline: none !important;
  box-shadow: none;
  margin-top: 30px;
  font-weight: bold;
}

.lager:focus{
  outline: none !important;
  box-shadow: none;
}

.btn-primary{
  outline: none !important;
  box-shadow: none;
  transition: all .2s;
}

.btn-primary:focus{
  outline: none !important;
  box-shadow: none;
  background-color: var(--color-14);
  color: var(--color-2);
  transform: translateY(-5px);
}

.btn-primary:hover{
  outline: none !important;
  box-shadow: none;
  background-color: var(--color-14);
  color: var(--color-2);
  transform: translateY(-5px);  
}

.information-text{
  padding: 1rem;
  background-color: var(--color-13);
  margin: 2rem 0 2rem 0;
  border-radius: 30px;
}

.information-text:focus{
  outline: none !important;
  box-shadow: none;
  border-bottom:2px rgba(213, 218, 221, 1) solid !important;
}

.p-text{
  padding: 1rem;
  font-size: 18px;
  font-weight: bold;
  color: var(--color-1);
}

.p-italic{
  font-style: italic;
  font-size: 16px;
  font-weight: normal;
}

.btn {
  float: right;
  outline: none !important;
  box-shadow: none !important;
  background-color: var(--color-1);
  border-color: var(--color-1);
  margin-bottom: 20px;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none;
}

.btn:hover{
  outline: none !important;
  border: none;
  box-shadow: none !important;
}

.custom-select-form{
  font-size: 16px;
  font-family: inherit;
  font-weight: normal;
  background: rgba(57, 57, 57, 0.07);
  margin: 12.5px 0;
  height: 40px;
  border: none;
  padding: 0 30px;
  border-radius: 10px;
}

.custom-select-form:focus{
  outline: none !important;
  border: 3px solid var(--color-1);
}

.custom-select-form:hover{
  background-color: rgba(57, 57, 57, 0.15);
}

.label-text{
  color: var(--color-1);
  font-weight: bold;
}

.question-group{
  font-weight: bold;
  margin: 2rem 0 2rem 0;
}

.question{
  font-weight: bold;
  margin-top: 2rem;
}


/* Custom Radio Button */
.rb {
  padding: 16px 0;
  text-align: center;
  background: rgba(255,255,255,.03);
  border-radius: .3em;
}

.rb-tab {
  display: inline-block;
  position: relative;
  width: 16%;
  cursor: pointer;
}

.rb-tab-active .rb-spot {
  background: var(--color-1);
  textcolor: var(--color-2);
  border: 2px solid var(--color-1);
}

.rb-tab-active .rb-txt {
  color: var(--color-2);
}

.rb-txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: .8em;
  font-weight: bold;
  color: var(--color-1);
}

.rb-txt-nvt {
  position: absolute;
  top: 210%;
  left: 50%;
  width: 110px;
  max-width: 110px;
  transform: translate(-50%, -50%);
  font-size: .8em;
  color: var(--color-1);
  text-align: center;
}

.rb-spot {
  position: absolute;
  width: 22px;
  height: 22px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border: 1.5px solid var(--color-1);
  border-radius: 100%;
  transition: border 0.15s ease, background-color 0.15s ease;
}

.rb-spot:hover,
.rb-spot:focus {
  outline: none;
  box-shadow: none;
  background-color: var(--color-14);
  color: var(--color-2) !important;
  border: 2px solid var(--color-14);
}

.rb-spot:hover .rb-txt,
.rb-spot:focus .rb-txt {
  color: var(--color-2);
}

.rb-input{
  visibility: hidden;
}



.paragraphs{
  background-color: var(--color-2) !important;
}

.custom-link {
  margin-right: 0px !important;
  outline: none;
  text-decoration: none;
  padding: 3px 3px 3px;
}

.link{
  margin-right: 0px !important;
  outline: none;
  text-decoration: none;
  padding: 3px 3px 3px;
  color: var(--color-1);
  font-weight: bold;
}

.link:visited {
  color: var(--color-11);
}

.link:hover {
  background-color: rgba(57, 57, 57, 0.15);
  border-radius: .5em;
}

.link:focus {
  outline: none !important;
  border-radius: .5em;
  border: 3px solid var(--color-1);
}

.btn-secondary {
  background: var(--color-1);
  font-weight: bold;
  height: 50px;
  padding-top: 5px !important;
  z-index: 12;
  margin-top: 30px;
  margin-right: 50px;
  width: 200px;
  max-width: 300px
}

.btn-secondary:focus {
  outline: none !important;
  box-shadow: none !important;
  background: #e8f0fe;
  color: var(--color-1);
  border:2px rgb(20,66,115) solid !important;
}

.btn-secondary:hover {
  outline: none !important;
  box-shadow: none !important;
  background: var(--color-4);
  border: var(--color-4);
}

.custom-alert{
  font-weight: bold;
}

.intro-section-colour-block {
  background: var(--color-13);
  width: 100%;
  padding: 5% 8% 8% 5%;
  color: #fff;
  position: relative;
  border: 5px solid var(--color-13);
  border-radius: 15px;
}

.intro-section-cc {
  width: 100%;
  height: 100px;
  position: relative;
  left: 0px;
  background: linear-gradient(to right bottom, #e3e7ed 49%, #e3e7ed 50%),
    linear-gradient(-50deg, #ffffff 16px, #000 0);
  margin-top: -100px;
  border-radius: 15px;
}

.intro-section-title{
  font-weight: bold;
  color: #1f2a44;
  margin-bottom: 2rem;
}

.intro-section-text{
  color: #1f2a44;
}
</style>
